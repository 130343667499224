/* global mapboxgl */

import React, { Component } from 'react'
import locationData from '../data/location-only-data.json'

const randomFloat = (max, min) => Math.random() * (max - min) + min
const randomIdx = len => Math.round(Math.random() * len)
const getRandomSelection = (array, size) => {
    let selection = []
    for (let i = 0; i < size; i++) {
        const element = array[randomIdx(array.length - 1)];
        element.properties = {
            size: randomFloat(1.5, 2.5)
        }
        selection.push(element)
    }
    return { type: 'FeatureCollection', features: selection }
}

class Map extends Component {
    constructor(props) {
        super(props)

        this.state = { loaded: false }
        this.container = 'react-map'
        this.defaultStyle = 'ryantm/cjp1l5uqf1a3w2sl4v01zhnj5'
    }

    componentDidMount() {
        const { lat, long, zoom, style, returnFn } = this.props
        const styleId = style || this.defaultStyle

        const mapConfig = {
            container: this.container,
            style: `mapbox://styles/${ styleId }`
        }

        if(lat && long) mapConfig.center = [long, lat]
        if(zoom) mapConfig.zoom = zoom

        mapboxgl.accessToken = 'pk.eyJ1IjoicnlhbnRtIiwiYSI6ImNpaDgycjExZzB0NDR1MWtpbWdkeDhxbmIifQ.AamjhGik8yPxK5V71kzHdw'
        this.map = new mapboxgl.Map(mapConfig)

        this.map.on('load', () => {
            const randData = getRandomSelection(locationData.features, randomIdx(300))
            console.log(randData)
            this.map.addSource('point', {
                "type": "geojson",
                "data": randData
            })
            this.map.addLayer({
                'id': 'projects',
                'source': 'point',
                'type': 'circle',
                'paint': {
                    'circle-radius': ['get', 'size'],
                    'circle-color': '#DD0000',
                    'circle-opacity': 1,
                    'circle-opacity-transition': { 'duration': 100 }
                }
            })
            this.map.moveLayer('andersenlocation', 'projects')
            this.map.setPaintProperty('andersenlocation', 'circle-opacity-transition', { 'duration': 2000 })
            
            const animateMarker = () => {
                this.map.getSource('point').setData(getRandomSelection(locationData.features, randomIdx(300)))
            }

            const animatePoint = () => {
                const opacity = this.map.getPaintProperty('andersenlocation', 'circle-opacity')
                this.map.setPaintProperty('andersenlocation', 'circle-opacity', opacity < 0.5 ? 1 : .05)
            }

            this.player = setInterval(animateMarker, 300)
            this.pointFader = setInterval(animatePoint, 1500)
        })
        window.map = this.map
        this.setState({ loaded: true })
        if(returnFn) returnFn(this.map)
    }

    componentWillUnmount() {
        clearInterval(this.player)
        clearInterval(this.pointFader)
        console.log('unmounting')
        this.map.remove()
    }

    render() {
        if(this.state.loaded) this.map.resize()

        return (
            <div id={ this.container } className='map'></div>
        );
    }
}

export default Map;