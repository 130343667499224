import React from 'react'
import Map from './Map.jsx'

import './style/Home.css'

const Home = () => {
    const lng = -122.9352921128135
    const lat = 45.470574933272786
    const zoom = 6.267369885838616
    return (
        <div>
            <Map lat={lat} long={lng} zoom={zoom} />
        </div>
    );
};

export default Home;