import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Menu from './components/Menu.jsx'
import Home from './components/Home.jsx'
import Projects from './components/Projects.jsx'
import Posts from './components/Posts.jsx'
import is from 'is_js'
// import People from './components/People.jsx'

import './App.css'
import './Fonts.css'

class App extends Component {
  constructor(props) {
    super(props)
    if(is.ie()) {
      alert('Internet Explorer is not supported by this website, and has been given an end-of-life by Microsoft. Please use the latest version of Chrome, Firefox, or Safari!')
      window.location.replace('https://www.mozilla.org/en-US/firefox/new/')
    }
  }
  render() {
    return (
      <Router>
        <div className="App">
          <Menu />
          <div>
            <Route exact path='/' component={ Home } />
            <Route exact path='/projects' component={ Projects } />
            <Route exact path='/posts' component={ Posts } />
            {/* <Route exact path='/people' component={ People } /> */}
          </div>
        </div>
      </Router>
    )
  }
}

export default App