import React from 'react'
import Project from './Project.jsx'
import './style/Content.css'

import projectData from '../data/projects.json'

const Projects = () => {
    const projects = projectData.map((project, i) => <div><Project key={ i } project={ project } /><hr className='project-seperator'/></div>)
    return (
        <div className='limiter contentWrapper'>
            <div className='project-box'>
                { projects }
            </div>
        </div>
    )
}

export default Projects