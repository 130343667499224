import React from 'react'
import postData from '../data/posts.json'

import './style/Writing.css'
import './style/Post.css'

const Posts = () => {
    const posts = postData.map(post => <Post post={ post } />)
    return (
        <div className='limiter contentWrapper'>
            <div className='project-box' >
                { posts }
            </div>
        </div>
    );
};

const Post = ({ post }) => {
    console.log(post)
    return (
        <a href={ post.url } target="_blank" className='post'>
            <img alt="" src={ post.image } />
            <div className='post-copy'>
                <strong>{ post.title }</strong>
                <span className='post-description'>
                    { `${post.by} | ${post.publisher}` }
                </span>
            </div>
        </a>
    )
}


export default Posts;