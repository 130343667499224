import React from 'react'
import { NavLink, Link } from 'react-router-dom'

import './style/Menu.css'

const Menu = () => {
    return (
        <div className='header-wrap'>
          <div className='header limiter'>
            <Link to='/'>
              <div className='companyTitle'>
                <i className="icon-beaker"></i> 
                <div className='companyName'>Andersen</div>
                <div className='subHeader'>Innovation Lab</div>
              </div>
            </Link>
            <ul className='links'>
              <NavLink to='/projects' activeClassName='activeLink'><li>Projects</li></NavLink>
              <NavLink to='/posts' activeClassName='activeLink'><li>Posts</li></NavLink>
              {/* <NavLink to='/people' activeClassName='activeLink'><li>People</li></NavLink> */}
            </ul>
          </div>
        </div>
    )
}

export default Menu