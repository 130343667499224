import React from 'react';
import './style/Project.css'

const Project = ({ project }) => {
    return (
        <a href={ project.link } target="_blank" className='project'>
            {/* <img alt="" src='https://via.placeholder.com/800x600' /> */}
            <img alt="" src={ project.img } />
            <div className='project-copy'>
                <strong>{ project.name }</strong>
                <br />
                <span className='project-description'>
                    { project.desc }
                </span>
            </div>
        </a>
    );
};

export default Project;